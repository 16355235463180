<template>
  <RevDialog
    :close-button-label="i18n(dsTranslations.close)"
    :name="REVIEW_FORM_CARE_MODAL"
    :title
  >
    <template #body>
      <div class="py-6 md:py-7">
        <p>{{ text }}</p>
      </div>
    </template>

    <template #footer="{ close }">
      <RevButton
        full-width="always"
        :to="
          resolveLocalizedRoute({
            name: CUSTOMER_CARE.DIAGNOSIS.SELF,
            params: { orderlineId: params.uuid },
          })
        "
        :tracking="{
          name: 'go_help_center',
          zone: 'review_collection',
        }"
        variant="primary"
        @click="close"
      >
        {{ i18n(translations.careModalButton) }}
      </RevButton>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { dsTranslations } from '@ds/translations'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'

import { useReviewCollectionSource } from '../../composables/useReviewCollectionSource'

import { REVIEW_FORM_CARE_MODAL } from './CareModal.constants'
import translations from './CareModal.translations'

const i18n = useI18n()
const { params } = useRoute()

const { isSolvedClaim } = useReviewCollectionSource()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const title = computed(() =>
  isSolvedClaim.value
    ? i18n(translations.careModalTitleSolvedClaim)
    : i18n(translations.careModalTitle),
)
const text = computed(() =>
  isSolvedClaim.value
    ? i18n(translations.careModalTextSolvedClaim)
    : i18n(translations.careModalText),
)
</script>
